const NONPROD_DEPLOYED_ENVIRONMENTS = [
  'deployed_dev',
  'integration',
  'staging',
]

export const DEPLOYED_ENVIRONMENTS = [
  ...NONPROD_DEPLOYED_ENVIRONMENTS,
  'production',
]

export const DEV_ENVIRONMENTS = [
  ...NONPROD_DEPLOYED_ENVIRONMENTS,
  'development',
]

export const FILTERED_KEYS = [
  'accessToken',
  'apiKey',
  'city',
  'email',
  'firstName',
  'first_name',
  'instructorFirstName',
  'instructorLastName',
  'lastName',
  'last_name',
  'password',
  'passwordConfirmation',
  'password_confirmation',
  'professorId',
  'state',
  'streetAddress',
  'secondaryAddress',
  'zip',
]

export const REDACTED_VALUE = '[FILTERED]'
