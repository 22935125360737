import { createSelector } from '@reduxjs/toolkit'
import camelize from 'camelize'
import find from 'lodash/find'
import moment from 'moment'

import { publisherNames } from '@/constants/publishers'
import { formatLicenseExpiration } from '@/utils/durationUtils'
import { translate } from '@/utils/translations'

import { SEARCHABLE_ROLE } from '../user/helpers'
import {
  isLicenseValid,
} from './helpers'

const PACKAGE = 'Package'
const PERPETUAL = 'perpetual'
const SINGLE_VARIANT = 'Single'
const PDP_TAB_TRANSLATION_KEY = 'return_policy.pdp_tab'

export const bookTitleSelector = createSelector(
  [
    state => state.asset.title,
  ],
  title => title
)

export const bookSubtitleSelector = createSelector(
  [state => state.asset.subtitle],
  subtitle => subtitle
)

export const otherIdentifiersSelector = createSelector(
  [state => state.asset.productDetails.otherIdentifiers],
  isbns => isbns
)

export const bisacSubjectsSelector = createSelector(
  [state => state.asset.productDetails.bisacSubjects],
  subjects => subjects
)

export const hasBisacSubjectsSelector = createSelector(
  [state => state.asset.productDetails.bisacSubjects.length],
  length => length > 1
)

export const variantsCountSelector = createSelector(
  [state => state.asset.variantOrder],
  variants => variants.length
)

export const isRepeatBillingSubscriptionSelector = createSelector(
  [state => state.asset.isRepeatBillingSubscription],
  isRepeatBillingSubscription => isRepeatBillingSubscription
)

const isOnlineResourceVariantSelector = createSelector(
  [
    state => state.asset.isOnlineResource,
    state => state.asset.selectedVariant.duration
  ],
  (isOnlineResource, duration) => (
    isOnlineResource && duration === PERPETUAL
  )
)

export const hasOneVariantSelector = createSelector(
  [variantsCountSelector],
  count => count === 1
)

const firstVariantSelector = createSelector(
  [
    state => state.asset.variantOrder,
    state => state.asset.variants
  ],
  (variantOrder, variants) => variants[variantOrder[0]]
)

export const isTextbookSelector = createSelector(
  [
    state => state.asset.isBundle,
    state => state.asset.isOnlineResource
  ],
  (bundle, onlineResource) => !bundle && !onlineResource
)

export const quantitySelector = createSelector(
  [state => state.asset.quantity],
  quantity => quantity === '' ? 1 : quantity
)

export const isLicenseInUserLibrarySelector = createSelector(
  [
    state => state.user.library.licenses,
    state => state.asset.identifiers
  ],
  (licenses, identifiers) => isLicenseValid({ identifiers, licenses })
)

export const licenseDaysRemainingSelector = createSelector(
  [state => state.user.library.licenses],
  licenses => {
    if (licenses.length === 0) { return 0 }

    const { expiration } = find(licenses, license => license)
    const MS_TO_EXPIRATION = moment(expiration) - moment()
    const daysLeft = Math.ceil(moment.duration(MS_TO_EXPIRATION).asDays())

    return daysLeft > 0 ? daysLeft : 0
  }
)

const hasLicenseLessThanAYearSelector = createSelector(
  [
    state => state.user.library.licenses,
    licenseDaysRemainingSelector
  ],
  (license, remainDays) => license && remainDays > 0 && remainDays <= 365
)

export const hasExtendableLicenseSelector = createSelector(
  [
    isLicenseInUserLibrarySelector,
    hasLicenseLessThanAYearSelector
  ],
  (hasLicense, hasLicenseLessThanAYear) => hasLicense && hasLicenseLessThanAYear
)

export const canExtendLicenseSelector = createSelector(
  [
    state => state.asset.canRepurchaseAsset,
    hasExtendableLicenseSelector
  ],
  (canRepurchaseAsset, hasExtendableLicense) => (
    canRepurchaseAsset && hasExtendableLicense
  )
)

const hasPerpetualOptionSelector = createSelector(
  [state => state.asset.variants],
  variants => (
    Object.values(variants).some(({ duration }) => duration === PERPETUAL)
  )
)

const singleVariantSelector = createSelector(
  [
    state => state.asset.selectedVariant.type,
    state => state.asset.selectedVariant.duration
  ],
  (type, duration) => {
    return type === SINGLE_VARIANT && duration === PERPETUAL
  }
)

const multipleVariantsTitleSelector = createSelector(
  [canExtendLicenseSelector],
  canExtendLicense => {
    const key = canExtendLicense ? 'extend_rental' : 'rent_or_buy_etextbook'

    return translate(`purchase.${key}`)
  }
)

const singleVariantTitleSelector = createSelector(
  [
    firstVariantSelector,
    canExtendLicenseSelector
  ],
  (firstVariant, canExtendLicense) => {
    const isPerpetual = firstVariant.duration === PERPETUAL
    const rentOrBuyKey = isPerpetual ? 'buy_book' : 'rent_etextbook'
    const key = canExtendLicense ? 'extend_rental' : rentOrBuyKey

    return translate(`purchase.${key}`)
  }
)

const bookVariantContainerTitleSelector = createSelector(
  [
    variantsCountSelector,
    multipleVariantsTitleSelector,
    singleVariantTitleSelector
  ],
  (
    numberOfVariants,
    multipleVariantsTitle,
    singleVariantTitle
  ) => (numberOfVariants === 1 ? singleVariantTitle : multipleVariantsTitle)
)

const onlineResourceVariantContainerTitleSelector = createSelector(
  [state => state.asset.isBundle],
  isBundle => (
    isBundle
      ? translate('purchase.rent_or_buy_etextbook_courseware_included')
      : translate('common.courseware')
  )
)

const isOnlineResourcePricingPanelSelector = createSelector(
  [
    state => state.asset.isOnlineResource,
    state => state.asset.isBundle,
    state => state.asset.isSubscription
  ],
  (
    isOnlineResource,
    isBundle,
    isSubscription,
  ) => isBundle || isOnlineResource || isSubscription
)

const isBookPricingPanelSelector = createSelector(
  [
    hasPerpetualOptionSelector,
    variantsCountSelector
  ],
  (hasPerpetualOption, numberOfVariants) => (
    hasPerpetualOption || numberOfVariants === 1
  )
)

export const variantSelectorTitleSelector = createSelector(
  [
    isRepeatBillingSubscriptionSelector,
    isOnlineResourcePricingPanelSelector,
    onlineResourceVariantContainerTitleSelector,
    isBookPricingPanelSelector,
    bookVariantContainerTitleSelector
  ],
  (
    isRepeatBillingSubscription,
    isOnlineResourcePricingPanel,
    onlineResourceVariantContainerTitle,
    isBookPricingPanel,
    bookVariantContainerTitle
  ) => {
    if (isRepeatBillingSubscription) {
      return translate('purchase.monthly_subscription')
    } else if (isOnlineResourcePricingPanel) {
      return onlineResourceVariantContainerTitle
    } else if (isBookPricingPanel) {
      return bookVariantContainerTitle
    } else {
      return translate('purchase.rent_etextbook')
    }
  }
)

export const formatLicenseExpirationSelector = createSelector(
  [
    state => state.asset.selectedVariant.durationInDays,
    licenseDaysRemainingSelector
  ],
  (durationinDays, daysLeftInLicense) => (
    formatLicenseExpiration(durationinDays, daysLeftInLicense)
  )
)

const expirationTextSelector = createSelector(
  [canExtendLicenseSelector],
  canExtendLicense => {
    const key = canExtendLicense ? 'new_expiration' : 'expires_on'

    return translate(`common.${key}`)
  }
)

const expirationDateSelector = createSelector(
  [
    expirationTextSelector,
    formatLicenseExpirationSelector
  ],
  (expirationText, formatLicenseExpiration) => (
    `${expirationText}: ${formatLicenseExpiration}`
  )
)

export const expirationSelector = createSelector(
  [
    expirationDateSelector,
    isOnlineResourceVariantSelector,
    singleVariantSelector
  ],
  (
    expirationDate,
    isOnlineResourceVariant,
    singleVariant
  ) => {
    if (!isOnlineResourceVariant && !singleVariant) {
      return expirationDate
    }
  }
)

export const isPerpetualPackageSelector = createSelector(
  [
    state => state.asset.selectedVariant.duration,
    state => state.asset.kind
  ],
  (duration, kind) => (
    duration === PERPETUAL && kind === PACKAGE
  )
)

export const coursewareButtonTextSelector = createSelector(
  [state => state.asset.isSubscription],
  isSubscription => (
    isSubscription
      ? translate('common.launch')
      : translate('common.launch_courseware')
  )
)

export const isShowingProceedToCheckoutSelector = createSelector(
  [
    state => state.asset.inCart,
    state => state.miniCart.isEnabled
  ],
  (inCart, isMiniCartEnabled) => inCart && !isMiniCartEnabled
)

export const isShowingVariantOptionsSelector = createSelector(
  [
    state => state.user.searchableRole.name,
    state => state.asset.isSampleable
  ],
  (name, isSampleable) => {
    switch (name) {
      case SEARCHABLE_ROLE.student:
        return true
      case SEARCHABLE_ROLE.educator:
        return isSampleable === false
      case SEARCHABLE_ROLE.publisher:
        return false
    }
  }
)

const accessibilityFeaturesSelector = createSelector(
  [state => state.asset.productDetails.accessibilityFeatures],
  features => camelize(features)
)

export const onixCodesFeaturesSelector = createSelector(
  [state => state.asset.productDetails.accessibilityFeatures.onix],
  features => camelize(features)
)

const publisherA11yFeaturesSelector = createSelector(
  [state => state.asset.productDetails.accessibilityFeatures.publisher],
  publisherFeatures => camelize(publisherFeatures)
)

export const publisherCertificationSelector = createSelector(
  [publisherA11yFeaturesSelector],
  ({ certification }) => camelize(certification)
)

export const publisherMetadataSelector = createSelector(
  [publisherA11yFeaturesSelector],
  ({ metadata }) => camelize(metadata)
)

const sourceFileA11yFeaturesSelector = createSelector(
  [state => state.asset.productDetails.accessibilityFeatures.sourceFile],
  sourceFilefeatures => camelize(sourceFilefeatures)
)

export const sourceFileCertificationSelector = createSelector(
  [sourceFileA11yFeaturesSelector],
  ({ certification }) => camelize(certification)
)

export const sourceFileMetadataSelector = createSelector(
  [sourceFileA11yFeaturesSelector],
  ({ metadata }) => camelize(metadata)
)

export const hasOnixCodesSelector = createSelector(
  [onixCodesFeaturesSelector],
  onixCodes => Object.values(onixCodes).some(value => value !== null)
)

export const hasPublisherCertificationSelector = createSelector(
  [publisherCertificationSelector],
  publisherCertification => (
    Object.values(publisherCertification).some(value => value !== null)
  )
)

export const hasPublisherMetadataSelector = createSelector(
  [publisherMetadataSelector],
  publisherMetadata => (
    Object.values(publisherMetadata).some(value => value !== null)
  )
)

export const a11yPublisherSummarySelector = createSelector(
  [state => state.asset.productDetails.accessibilityFeatures.publisher.summary],
  summary => summary
)

export const hasPublisherFeaturesSelector = createSelector(
  [
    a11yPublisherSummarySelector,
    hasPublisherCertificationSelector,
    hasPublisherMetadataSelector,

  ],
  (summary, hasCertification, hasMetadata) => (
    Boolean(summary) || hasCertification || hasMetadata
  )
)

export const hasSourceFileCertificationSelector = createSelector(
  [sourceFileCertificationSelector],
  sourceFileCertification => (
    Object.values(sourceFileCertification).some(value => value !== null)
  )
)

export const hasSourceFileMetadataSelector = createSelector(
  [sourceFileMetadataSelector],
  sourceFileMetadata => (
    Object.values(sourceFileMetadata).some(value => value !== null)
  )
)

export const hasAccessibilityFeaturesSelector = createSelector(
  [accessibilityFeaturesSelector],
  features => Boolean(features)
)

export const a11ySourceFileSummarySelector = createSelector(
  [sourceFileA11yFeaturesSelector],
  ({ summary }) => summary
)

export const accessibilitySummarySelector = createSelector(
  [
    a11ySourceFileSummarySelector,
    a11yPublisherSummarySelector
  ],
  (sourceFileSummary, publisherSummary) => sourceFileSummary || publisherSummary
)

const accessModeSufficientValuesSelector = createSelector(
  [
    sourceFileMetadataSelector,
    publisherMetadataSelector
  ],
  (sourceMetadata, publisherMetadata) => {
    const sourceAccessMode = sourceMetadata.accessModeSufficient
    const publisherAccessMode = publisherMetadata.accessModeSufficient
    let values = []

    if (sourceAccessMode) {
      values = values.concat(sourceAccessMode)
    }
    if (publisherAccessMode) {
      values = values.concat(publisherAccessMode)
    }

    return values
  }
)

export const screenReaderFriendlyTextSelector = createSelector(
  [accessModeSufficientValuesSelector],
  accessModeSufficient => {
    const isUnsupported = accessModeSufficient.length === 0

    const options = {
      supported: { status: 'supported', text: translate('common.yes') },
      unknown: {
        status: 'unknown',
        text: translate('product_display.accessibility.unknown_or_not_provided')
      },
      unsupported: { status: 'unsupported', text: translate('common.no') }
    }
    let status = options.unknown.status

    if (isUnsupported) return options.unsupported.text

    if (accessModeSufficient.includes('textual')) {
      status = options.supported.status
    }

    return options[status].text
  }
)

const tableOfContentsExistsSelector = createSelector(
  [state => state.asset.productDetails.tableOfContentsPath],
  path => Boolean(path)
)

const tableOfContentsAlreadyRequestedSelector = createSelector(
  [
    state => state.asset.tableOfContents.response,
  ],
  response => response && response.length > 0
)

export const isRequestingTableOfContentsSelector = createSelector(
  [
    tableOfContentsExistsSelector,
    tableOfContentsAlreadyRequestedSelector
  ],
  (assetHasTableOfContents, hasAlreadyRequested) => (
    assetHasTableOfContents && !hasAlreadyRequested
  )
)

export const isRequestingOnlineResourceSelector = createSelector(
  [
    state => state.user.isVisitor,
    state => state.asset.isBundle,
    state => state.asset.isOnlineResource
  ],
  (isVisitor, isBundle, isOnlineResource) => (
    !isVisitor && (isOnlineResource || isBundle)
  )
)

export const isCoursewareWebsiteSelector = createSelector(
  [
    isRequestingOnlineResourceSelector,
    isLicenseInUserLibrarySelector
  ],
  (isRequesting, hasLicense) => isRequesting && hasLicense
)

export const isShowingBookshelfLoginLinkSelector = createSelector(
  [
    state => state.asset.onlineResource.isLoading,
    state => state.asset.onlineResource.loginURL
  ],
  (isLoading, loginURL) => isLoading && !loginURL
)

export const taxInfoMessageSelector = createSelector(
  [state => state.currentRegion.taxShortInfoMessage],
  taxInfoMessage => taxInfoMessage
)

export const isFreeSelector = createSelector(
  [state => state.asset.isFree],
  isFree => isFree
)

export const showTaxInfoMessageSelector = createSelector(
  [taxInfoMessageSelector, isFreeSelector],
  (taxInfoMessage, isFree) => taxInfoMessage && !isFree
)

export const onlineResourceButtonTextSelector = createSelector(
  [
    state => state.asset.features.hasNotCoursewareFeature,
    coursewareButtonTextSelector
  ],
  (hasNotCoursewareFeature, coursewareButtonText) => (hasNotCoursewareFeature
    ? translate('common.access_content')
    : coursewareButtonText
  )
)

export const coursewareAccessTitleSelector = createSelector(
  [
    state => state.asset.features.hasNotCoursewareFeature
  ],
  isAccessCodeOnly => isAccessCodeOnly
    ? translate('purchase.how_to_redeem_access_code')
    : translate('purchase.courseware_access')
)

export const isShowingHowToVideoSelector = createSelector(
  [
    state => state.asset.isLoaded,
    state => state.asset.format
  ],
  (isLoaded, format) => {
    return isLoaded && (format === 'PDF' || 'ePub')
  }
)

export const returnPolicySelector = createSelector(
  [state => state.asset.returnPolicy],
  policy => policy
)

export const returnPolicyNameSelector = createSelector(
  [state => state.asset.returnPolicy.name],
  name => name
)

export const returnPolicyDaysSelector = createSelector(
  [state => state.asset.returnPolicy.returnWithinDays],
  returnWithinDays => returnWithinDays
)

export const isPublisherReturnPolicySelector = createSelector(
  [returnPolicyNameSelector],
  name => name !== publisherNames.vitalSource
)

export const unusedCodesWithinDaysSelector = createSelector(
  [state => state.asset.returnPolicy.unusedCodesWithinDays],
  codeDays => codeDays
)

export const studentStorePolicySelector = createSelector(
  [
    isPublisherReturnPolicySelector,
    returnPolicyNameSelector
  ],
  (isPublisherReturnPolicy, publisherName) => (
    isPublisherReturnPolicy
      ? translate(
        `${PDP_TAB_TRANSLATION_KEY}.publisher_return_policy`,
        { publisher: publisherName }
      )
      : translate(`${PDP_TAB_TRANSLATION_KEY}.vst_return_policy`)
  )
)

export const returnPolicyLinkTextSelector = createSelector(
  [state => state.currentRegion.bulkEnabled],
  isBulkEnabled => {
    const translationKey = isBulkEnabled
      ? 'exceptions_link' : 'view_all_policies'

    return translate(`${PDP_TAB_TRANSLATION_KEY}.${translationKey}`)
  }
)

export const returnPolicyExplanationTextSelector = createSelector(
  [state => state.currentRegion.bulkEnabled],
  isBulkEnabled => {
    const translationKey = isBulkEnabled ? 'explanation' : 'met'

    return translate(`${PDP_TAB_TRANSLATION_KEY}.${translationKey}`)
  }
)

export const returnPolicyModalHeaderSelector = createSelector(
  [
    state => state.currentRegion.bulkEnabled,
    studentStorePolicySelector
  ],
  (isBulkEnabled, studentStorePolicy) => (isBulkEnabled
    ? translate('return_policy.modal.bulk_rules.header')
    : studentStorePolicy
  )
)
