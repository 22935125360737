require('./index.scss')

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Dialog from '@/components/Dialog'

import FirstStep from './steps/First'
import LastStep from './steps/Last'

const OnlineResourceModal = ({
  isSubscription,
  isShowing,
  onChangeShow,
  title,
  trigger,
  vbid
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const isSubscriptionAsset = useSelector(
    state => state.asset.isSubscription
  ) || Boolean(isSubscription)

  return (
    <Dialog
      heading={title}
      id='online-resource-modal'
      isShowing={isShowing}
      onChangeShow={onChangeShow}
      theme='cheddar'
      trigger={trigger}
    >
      <Choose>
        <When condition={currentStep === 1}>
          <FirstStep {...{
            isSubscriptionAsset,
            setCurrentStep,
          }}
          />
        </When>
        <Otherwise>
          <LastStep {...{ setCurrentStep, vbid }} />
        </Otherwise>
      </Choose>
    </Dialog>
  )
}

export default OnlineResourceModal

OnlineResourceModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  isSubscription: PropTypes.bool,
  onChangeShow: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.element,
  vbid: PropTypes.string
}
