import PropTypes from 'prop-types'
import React from 'react'

import ReadNow from '@/components/ReadNow'

import WatkinsReadNowInBookshelf from './watkins_read_now_in_bookshelf.svg'

const AssetPurchasedCard = ({
  readNowButtonProps: {
    isBundle,
    isOnlineResource,
    isShowingCustomerSupportUrl,
    isShowingExpirationStatus,
    readNowExpirationDays,
    readNowUrl,
    supportUrl,
    translations
  }
}) => {

  return(
    <div className='asset-purchased card'>
      <img
        alt=''
        className='watkins-read-bookshelf-image'
        src={WatkinsReadNowInBookshelf}
      />
      <ReadNow {...{
        isBundle,
        isOnlineResource,
        isShowingCustomerSupportUrl,
        isShowingExpirationStatus,
        readNowExpirationDays,
        readNowUrl,
        supportUrl,
        translations
      }}
      />
    </div>
  )
}

export default AssetPurchasedCard

AssetPurchasedCard.propTypes = {
  readNowButtonProps: PropTypes.shape({
    isBundle: PropTypes.bool.isRequired,
    isOnlineResource: PropTypes.bool.isRequired,
    isShowingCustomerSupportUrl: PropTypes.bool.isRequired,
    isShowingExpirationStatus: PropTypes.bool.isRequired,
    readNowExpirationDays: PropTypes.string.isRequired,
    readNowUrl: PropTypes.string.isRequired,
    supportUrl: PropTypes.string.isRequired,
    translations: PropTypes.shape({
      common_access_code_send_by_email: PropTypes.string.isRequired,
      common_customer_support: PropTypes.string.isRequired,
      common_read_in_bookshelf: PropTypes.string.isRequired
    })
  })
}
