import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const update = {
  updateFailure: (state, _action) => ({
    ...state,
    isUpdating: false
  }),
  updateStart: state => ({
    ...state,
    isUpdating: true
  }),
  updateSuccess: (state, { payload: campaign }) => ({
    campaigns: {
      ...state.campaigns,
      [campaign.id]: campaign
    },
    isUpdating: false,
  })
}

const initialLoad = (state, { payload }) => ({
  ...state,
  campaigns: {
    ...state.campaigns,
    ...payload[SLICES.publisherCampaigns]
  }
})

export const reducers = {
  ...update
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
