import PropTypes from 'prop-types'
import React from 'react'

import ReadNow from '@/components/ReadNow'

const MANAGE_SUBSCRIPTION_URL = '/subscriptions'
const MANAGE_SUBSCRIPTION_CATEGORY = 'SubscriptionManager'
import Total from '@/components/Total'

const classNames = [
  'vst-btn--action',
  'vst-btn--manage-subscription',
  'vst-btn--orange-outline',
  'u-weight--normal'
].join(' ')

const SubscriptionAssetPurchasedCard = ({
  currency,
  isRepeatBillingSubscription,
  isZeroDecimalCurrency,
  location,
  readNowButtonProps,
  taxes,
  title,
  total,
  translations
}) => {
  const {
    common_manage_subscription
  } = translations

  return (
    <div className='asset-purchased card'>
      <legend className='title'>
        {title}
      </legend>

      <Total {...{
        currency,
        isRepeatBillingSubscription,
        isZeroDecimalCurrency,
        taxes,
        total
      }}
      />

      <ReadNow {...{
        ...readNowButtonProps
      }}
      />

      <a
        className={classNames}
        data-action={location}
        data-category={MANAGE_SUBSCRIPTION_CATEGORY}
        href={MANAGE_SUBSCRIPTION_URL}
      >
        {common_manage_subscription}
      </a>
    </div>
  )
}

export default SubscriptionAssetPurchasedCard

SubscriptionAssetPurchasedCard.propTypes = {
  currency: PropTypes.shape({
    label: PropTypes.string.isRequired,
    showing: PropTypes.bool.isRequired,
    storeCurrency: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired
  }),
  isRepeatBillingSubscription: PropTypes.bool.isRequired,
  isZeroDecimalCurrency: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  readNowButtonProps: PropTypes.shape({
    isBundle: PropTypes.bool.isRequired,
    isOnlineResource: PropTypes.bool.isRequired,
    isShowingCustomerSupportUrl: PropTypes.bool.isRequired,
    isShowingExpirationStatus: PropTypes.bool.isRequired,
    location: PropTypes.string.isRequired,
    readNowExpirationDays: PropTypes.string.isRequired,
    readNowUrl: PropTypes.string.isRequired,
    supportUrl: PropTypes.string.isRequired,
  }),
  taxes: PropTypes.shape({
    flatTaxMultiplier: PropTypes.number,
    taxMultiplier: PropTypes.number,
  }),
  title: PropTypes.string.isRequired,
  total: PropTypes.object.isRequired,
  translations: PropTypes.shape({
    common_manage_subscription: PropTypes.string.isRequired,
    common_no_price_product: PropTypes.string.isRequired,
    purchase_per_month: PropTypes.string.isRequired,
  })
}
