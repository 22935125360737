import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const fetch = {
  fetchFailure: (state, _action) => ({
    ...state,
    isLoading: false,
    requestFailed: true
  }),
  fetchStart: state => ({
    ...state,
    isLoading: true,
    requestFailed: false
  }),
  fetchSuccess: (_state, { payload: availableExports }) => ({
    availableExports: availableExports,
    isLoading: false,
  })
}

const initialLoad = (state, { payload }) => ({
  ...state,
  campaigns: {
    ...state.campaigns,
    ...payload[SLICES.publisherCampaigns]
  }
})

export const reducers = {
  ...fetch,
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
