import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  recommendations: {}
}

const instructorRecommendations = createSlice({
  extraReducers,
  initialState,
  name: SLICES.instructorRecommendations,
  reducers
})

export const { actions } = instructorRecommendations
export default instructorRecommendations
