import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { translate } from '@/utils/translations'

const ReturnPolicy = ({ isSubscription }) => {
  const returnPolicyUrl = useSelector(
    state => state.currentRegion.returnPolicyUrl
  )

  return (
    <If condition={!isSubscription}>
      <p>
        {translate('assets.show.online_resource.modal.refund')}
        <a
          className='link--branded'
          data-category='OnlineResourceModal'
          href={returnPolicyUrl}
          rel='noopener noreferrer'
          target='_blank'
        >
          {translate('layouts.footer.return_policy')}
        </a>
      </p>
    </If>
  )
}

export default ReturnPolicy

ReturnPolicy.propTypes = { isSubscription: PropTypes.bool.isRequired }
