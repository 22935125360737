import PropTypes from 'prop-types'
import React from 'react'

import MessageOnlyButton from '@/components/MessageOnlyButton'

import InstructorAddToLibraryButton from './InstructorAddToLibraryButton'
import InstructorRenewButton from './InstructorRenewButton'
import RequestSampleButton from './RequestSampleButton'

const Sampleable = ({
  hasPendingSampleRequests,
  isLicenseExpired,
  isVerifiedInstructor,
  isPendingEmailVerification,
  routes,
  translations
}) => {

  const {
    common_add_to_library,
    common_pending_email_verification,
    common_pending_publisher_approval,
    common_renew,
    purchase_request_sample
  } = translations

  const {
    new_instructor_asset_sampling_request,
    new_instructor_asset_sampling_record,
    renew_instructor_asset_sampling_records
  } = routes

  return (
    <Choose>
      <When condition={hasPendingSampleRequests}>
        <MessageOnlyButton
          message={common_pending_publisher_approval}
        />
      </When>
      <When condition={isLicenseExpired}>
        <InstructorRenewButton
          routes={{ renew_instructor_asset_sampling_records }}
          translations={{ common_renew }}
        />
      </When>
      <Otherwise>
        <Choose>
          <When condition={isVerifiedInstructor}>
            <Choose>
              <When condition={isPendingEmailVerification}>
                <MessageOnlyButton
                  message={common_pending_email_verification}
                />
              </When>
              <Otherwise>
                <InstructorAddToLibraryButton
                  route={new_instructor_asset_sampling_record}
                  text={common_add_to_library}
                />
              </Otherwise>
            </Choose>
          </When>
          <Otherwise>
            <RequestSampleButton
              routes={{ new_instructor_asset_sampling_request }}
              translations={{ purchase_request_sample }}
            />
          </Otherwise>
        </Choose>
      </Otherwise>
    </Choose>
  )
}

export default Sampleable

Sampleable.propTypes = {
  hasPendingSampleRequests: PropTypes.bool.isRequired,
  isLicenseExpired: PropTypes.bool.isRequired,
  isPendingEmailVerification: PropTypes.bool.isRequired,
  isVerifiedInstructor: PropTypes.bool.isRequired,
  routes: PropTypes.shape({
    new_instructor_asset_sampling_record: PropTypes.string.isRequired,
    new_instructor_asset_sampling_request: PropTypes.string.isRequired,
    renew_instructor_asset_sampling_records: PropTypes.string.isRequired
  }),
  translations: PropTypes.shape({
    common_add_to_library: PropTypes.string.isRequired,
    common_pending_email_verification: PropTypes.string.isRequired,
    common_pending_publisher_approval: PropTypes.string.isRequired,
    common_renew: PropTypes.string.isRequired,
    purchase_request_sample: PropTypes.string.isRequired
  })
}
