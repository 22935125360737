import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {
  availableExports: [],
  isLoading: false,
  requestFailed: false,
}

const samplingInstructorRequestsExports = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.samplingInstructorRequestsExports,
  reducers: reducers
})

export const { actions } = samplingInstructorRequestsExports

export default samplingInstructorRequestsExports
