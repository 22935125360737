import PropTypes from 'prop-types'
import React from 'react'
const classNames = [
  'vs-btn--action',
  'message_only_action',
  'vst-btn--read-now',
  'u-weight--normal'
].join(' ')

const MessageOnlyButton = ({ message }) => (
  <button
    className={classNames}
    disabled
  >
    {message}
  </button>
)

export default MessageOnlyButton

MessageOnlyButton.propTypes = { message: PropTypes.string.isRequired }
