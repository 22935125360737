import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {
  companies: [],
  isFetching: true,
}

const publisherCompanies = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.publisherCompanies,
  reducers: reducers
})

export const { actions } = publisherCompanies
export default publisherCompanies
