import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'
import { mergeKeys } from '@/reducers/utils/helpers'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.publisherInstructorSearch]
})

const fetch = {
  fetchFailure: (state, { payload: error }) => mergeKeys(
    state,
    { status: { error, fetching: false } }
  ),
  fetchStart: (state, _action) => mergeKeys(
    state,
    { status: { error: null, fetching: true } }
  ),
  fetchSuccess: (state, { payload: { results } }) => ({
    ...state,
    searchSuggestions: results,
    status: { ...state.status, fetching: false }
  })
}

const resetSearch = (state, _action) => ({
  ...state,
  searchSuggestions: [],
  status: { ...state.status, fetching: false }
})

const setQuery = (state, { payload }) =>({
  ...state,
  query: payload
})

export const reducers = {
  ...fetch,
  resetSearch,
  setQuery
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
