export const SLICES = {
  accordion: 'accordion',
  activeRegions: 'activeRegions',
  activeSamplingRegions: 'activeSamplingRegions',
  adminPermissions: 'adminPermissions',
  adminRoleSwitcher: 'adminRoleSwitcher',
  asset: 'asset',
  billingAddress: 'billingAddress',
  bulkRedemptionGuidance: 'bulkRedemptionGuidance',
  currentLocale: 'currentLocale',
  currentRegion: 'currentRegion',
  dashboardMenu: 'dashboardMenu',
  expressCheckout: 'expressCheckout',
  featureFlags: 'featureFlags',
  flashMessages: 'flashMessages',
  ghostInspector: 'ghostInspector',
  institutions: 'institutions',
  instructorRecommendations: 'instructorRecommendations',
  lineItems: 'lineItems',
  miniCart: 'miniCart',
  mobileMenu: 'mobileMenu',
  modal: 'modal',
  onlineResourceModal: 'onlineResourceModal',
  productRecommendationForm: 'productRecommendationForm',
  promoCampaigns: 'promoCampaigns',
  publisherCampaigns: 'publisherCampaigns',
  publisherCompanies: 'publisherCompanies',
  publisherInstructorSearch: 'publisherInstructorSearch',
  publisherUsers: 'publisherUsers',
  recaptcha: 'recaptcha',
  regionRecommendation: 'regionRecommendation',
  samplingInstructorRequests: 'samplingInstructorRequests',
  samplingInstructorRequestsExports: 'samplingInstructorRequestsExports',
  samplingQuotas: 'samplingQuotas',
  subscriptionBilling: 'subscriptionBilling',
  taxes: 'taxes',
  user: 'user'
}

export const SHARED_ACTIONS = {
  billingAddress: {
    updateField: `${SLICES.billingAddress}/updateField`,
    updateStart: `${SLICES.billingAddress}/updateStart`,
    updateSuccess: `${SLICES.billingAddress}/updateSuccess`,
    updateSuccessV2: `${SLICES.billingAddress}/updateSuccessV2`,
  },
  lineItems: {
    createFailure: `${SLICES.lineItems}/createFailure`,
    createStart: `${SLICES.lineItems}/createStart`,
    createSuccess: `${SLICES.lineItems}/createSuccess`,
    destroyStart: `${SLICES.lineItems}/destroyStart`,
    destroySuccess: `${SLICES.lineItems}/destroySuccess`,
    updateStart: `${SLICES.lineItems}/updateStart`,
    updateSuccess: `${SLICES.lineItems}/updateSuccess`,
  },
  publisherCampaigns: {
    updateFailure: `${SLICES.publisherCampaigns}/updateFailure`,
    updateStart: `${SLICES.publisherCampaigns}/updateStart`,
  },
  publisherUsers: {
    getRowsFailure: `${SLICES.publisherUsers}/getRowsFailure`,
    getRowsStart: `${SLICES.publisherUsers}/getRowsStart`,
    grantAccessFailure: `${SLICES.publisherUsers}/grantAccessFailure`,
    importUsersFailure: `${SLICES.publisherUsers}/importUsersFailure`,
    importUsersStart: `${SLICES.publisherUsers}/importUsersStart`,
  },
  updateStateFromBackend: 'all/updateStateFromBackend'
}
