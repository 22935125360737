import PropTypes from 'prop-types'
import React from 'react'

const classNames = [
  'vst-btn--action',
  'vst-btn--orange',
  'vst-btn--read-now',
  'u-weight--normal',
  'instructor_add_to_library_action'
].join(' ')

const data = {
  action: 'AddToLibrary:SRP-PDP',
  category: 'Sampling:AddToLibrary'
}

const InstructorAddToLibraryButton = ({
  route,
  text
}) => (
  <a
    className={classNames}
    data-action={data.action}
    data-category={data.category}
    href={route}
  >
    {text}
  </a>
)

export default InstructorAddToLibraryButton

InstructorAddToLibraryButton.propTypes = {
  route: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}
