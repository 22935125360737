import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Tooltip from '@/components/Tooltip'
import { showTaxInfoMessageSelector, taxInfoMessageSelector } from '@/selectors'
import { translate } from '@/utils/translations'

import ReduxProvider from '../ReduxProvider'
import SkeletonThemeWrapper from '../SkeletonThemeWrapper'

const classList = [
  'type--cloud7',
  'vst-copy--tiny',
  'u-push--top',
  'u-weight--normal',
  'd-flex',
  'justify-content-center'
].join(' ')

const TaxInfoMessage = () => {
  const hasNZTaxRate = useSelector(state => state.asset.hasNZTax)
  const showTaxInfo = useSelector(showTaxInfoMessageSelector)
  const taxInfoMessage = useSelector(taxInfoMessageSelector)

  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    setIsShowing(true)
  })

  return (
    <If condition={showTaxInfo}>
      <Choose>
        <When condition={isShowing}>
          <div className={classList}>
            {' '}{taxInfoMessage}
            <If condition={hasNZTaxRate}>
              <Tooltip text={translate('tooltips.nz_tax')} />
            </If>
          </div>
        </When>
        <Otherwise>
          <SkeletonThemeWrapper count={1} />
        </Otherwise>
      </Choose>
    </If>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = () => (
  <ReduxProvider>
    <TaxInfoMessage />
  </ReduxProvider>
)
export default Component
