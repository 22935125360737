import moment from 'moment'

import { translate } from '@/utils/translations'

const PERPETUAL = 'perpetual'
const ZERO_DAYS = '0 days'

export const formatLicenseExpiration = (
  numberOfDaysAsString,
  daysLeftInLicense
) => {
  const duration = parseInt(numberOfDaysAsString, 10) + daysLeftInLicense

  return formatExpirationDate(duration)
}

export const formatExpirationDate = (numberOfDaysAsString, options) => {
  const expirationDate = moment().add(parseInt(numberOfDaysAsString, 10), 'd')

  if (options && options.printOrder) {
    return expirationDate.format('L')
  } else {
    return expirationDate.format('MMM D, YYYY')
  }
}

export const durationText = duration => duration === PERPETUAL
  ? translate('purchase.buy_eTextbook')
  : duration

export const lifetimeDurationText = bookshelfAccessDuration => {
  return bookshelfAccessDuration === ZERO_DAYS
    ? translate('purchase.downloaded_copy_does_not_expire')
    : translate(
      'purchase.downloaded_copy_includes',
      { duration: bookshelfAccessDuration }
    )
}
