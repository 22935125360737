import find from 'lodash/find'
import includes from 'lodash/includes'
import moment from 'moment'

export const isLicenseExpired = ({ identifiers, licenses }) => {
  if ( licenses.length === 0 ) return false
  const { vbid, expiration } = find(licenses, license => license )
  const today = moment()

  return includes(identifiers, vbid) && moment(expiration).isBefore(today)
}

export const isLicenseValid = ({ licenses, identifiers }) => {
  if ( licenses.length === 0 ) return false

  const { vbid, expiration } = find(licenses, license => license)

  const today = moment()

  return includes(identifiers, vbid) && moment(expiration).isAfter(today)
}
