import PropTypes from 'prop-types'
import React from 'react'

import CarbonNeutralityDialog from '../CarbonNeutralityDialog'
const CarbonNeutrality = ({
  canShowCarbonNeutrality
}) => {
  return(
    <div>
      <If condition={canShowCarbonNeutrality}>
        <CarbonNeutralityDialog />
      </If>
    </div>
  )
}

export default CarbonNeutrality

CarbonNeutrality.propTypes = {
  canShowCarbonNeutrality: PropTypes.bool.isRequired
}
