import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'
import { mergeKeys } from '@/reducers/utils/helpers'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.instructorRecommendations]
})

const failure = (state, { payload: error }) => mergeKeys(
  state,
  { status: { error, fetching: false } }
)

const start = (state, { payload: id }) => ({
  ...state,
  recommendations: {
    ...state.recommendations,
    [id]: { ...state.recommendations[id], inFlight: true }
  }
})

const success = (state, { payload: { id, status } }) => ({
  ...state,
  recommendations: {
    ...state.recommendations,
    [id]: { ...state.recommendations[id], inFlight: false, status }
  }
})

const accept = {
  acceptFailure: failure,
  acceptStart: start,
  acceptSuccess: success
}

const decline = {
  declineFailure: failure,
  declineStart: start,
  declineSuccess: success
}

const undo = {
  undoFailure: failure,
  undoStart: start,
  undoSuccess: success
}

export const reducers = {
  ...accept,
  ...decline,
  ...undo
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad
}
