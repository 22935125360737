require('./index.scss')

import VisuallyHidden from '@reach/visually-hidden'
import PropTypes from 'prop-types'
import React from 'react'

import ReadNowLink from '@/components/ReadNowLink'

import InstructorAddToLibraryButton
  from './InstructorAddToLibraryButton'
import Sampleable from './Sampleable'

const classNames = [
  'vst-btn--action',
  'vst-btn--orange',
  'vst-btn--read-now',
  'u-weight--normal'
].join(' ')

const EducatorActionButton = ({
  hasExpiredLicense,
  hasPendingSampleRequests,
  isShowingReadNowButton,
  isPendingEmailVerification,
  isSampleable,
  isFreeOrOER,
  isVerifiedInstructor,
  page,
  routes,
  translations

}) => {
  const {
    common_add_to_library,
    common_not_sampleable,
    common_read_in_bookshelf,
    common_renew,
    common_pending_email_verification,
    common_pending_publisher_approval,
    common_warning,
    purchase_request_sample
  } = translations

  const {
    new_instructor_asset_sampling_record,
    new_instructor_asset_sampling_request,
    renew_instructor_asset_sampling_records,
    read_now_url
  } = routes
  return (
    <Choose>
      <When condition={isShowingReadNowButton}>
        <ReadNowLink
          className={classNames}
          page={page}
          route={read_now_url}
        >
          {common_read_in_bookshelf}
        </ReadNowLink>
      </When>
      <When condition={isFreeOrOER}>
        <InstructorAddToLibraryButton
          route={new_instructor_asset_sampling_record}
          text={common_add_to_library}
        />
      </When>
      <When condition={isSampleable}>
        <Sampleable
          hasPendingSampleRequests={hasPendingSampleRequests}
          isLicenseExpired={hasExpiredLicense}
          isPendingEmailVerification={isPendingEmailVerification}
          isVerifiedInstructor={isVerifiedInstructor}
          routes={{
            new_instructor_asset_sampling_record,
            new_instructor_asset_sampling_request,
            renew_instructor_asset_sampling_records
          }}
          translations={{
            common_add_to_library,
            common_pending_email_verification,
            common_pending_publisher_approval,
            common_renew,
            purchase_request_sample
          }}
        />
      </When>
      <Otherwise>
        <p>
          <VisuallyHidden>
            {common_warning}{':'}
          </VisuallyHidden>
          {common_not_sampleable}
        </p>
      </Otherwise>
    </Choose>
  )
}

export default EducatorActionButton

EducatorActionButton.propTypes = {
  hasExpiredLicense: PropTypes.bool.isRequired,
  hasPendingSampleRequests: PropTypes.bool.isRequired,
  isFreeOrOER: PropTypes.bool.isRequired,
  isPendingEmailVerification: PropTypes.bool.isRequired,
  isSampleable: PropTypes.bool.isRequired,
  isShowingReadNowButton: PropTypes.bool.isRequired,
  isVerifiedInstructor: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
  routes: PropTypes.shape({
    instructor_asset: PropTypes.string.isRequired,
    new_instructor_asset_sampling_record: PropTypes.string.isRequired,
    new_instructor_asset_sampling_request: PropTypes.string.isRequired,
    read_now_url: PropTypes.string.isRequired,
    renew_instructor_asset_sampling_records: PropTypes.string.isRequired
  }),
  translations: PropTypes.shape({
    common_add_to_library: PropTypes.string.isRequired,
    common_not_sampleable: PropTypes.string.isRequired,
    common_pending_email_verification: PropTypes.string.isRequired,
    common_pending_publisher_approval: PropTypes.string.isRequired,
    common_read_in_bookshelf: PropTypes.string.isRequired,
    common_renew: PropTypes.string.isRequired,
    common_warning: PropTypes.string.isRequired,
    purchase_request_sample: PropTypes.string.isRequired
  })
}
