import { formatMoney } from 'accounting-js'

const consentUpdate = ({ payload }) => ({
  event: "consentUpdate",
  ...payload
})

const event = ({ payload: { name, props } }) => ({
  ...props,
  event: name
})

const formattedIsbn = isbn => {
  if (isbn !== undefined) {
    return isbn.replace(/-/g, '')
  }
}

const formattedName = ({ canonicalIsbn, title }) => {
  const isbn = formattedIsbn(canonicalIsbn)

  return `${title} - ${isbn}`
}

const formattedPriceWithFlatTax = ({ currentRegion, studentPrice }) => {
  const { currencySymbol, currencyPrecision, taxMultiplier } = currentRegion
  const flatTaxMultiplier = parseFloat(taxMultiplier)
  const price = studentPrice * flatTaxMultiplier

  if (price > 0) {
    return formatMoney(
      price, { precision: currencyPrecision, symbol: currencySymbol }
    )
  }
}

const assetProps = asset => ({
  canonicalIsbn: asset.canonicalIsbn,
  gtin: asset.gtin,
  imprintName: asset.imprintName,
  kind: asset.kind,
  productImageUrl: asset.productImageUrl,
  productUrl: asset.productUrl,
  slug: asset.slug,
  storeFeedSku: asset.storeFeedSku,
  title: asset.title
})

const itemProps = ({ asset, currentRegion, variant }) => {
  const { canonicalIsbn, imprintName, kind, title } = asset
  const { sku, studentPrice } = variant
  const name = formattedName({ canonicalIsbn, title })
  const id = formattedIsbn(canonicalIsbn)
  const priceTax = formattedPriceWithFlatTax({ currentRegion, studentPrice })

  return ({
    currency: currentRegion.storeCurrency,
    item_brand: imprintName,
    item_category: kind,
    item_id: id,
    item_name: name,
    item_variant: sku,
    price: studentPrice,
    priceTax: priceTax
  })
}

const recommendedItemProps = (
  { asset, currentRegion, list, position, variant }
) => {
  const { listId, listName } = list

  return ({
    ...itemProps({ asset, currentRegion, variant }),
    index: position,
    item_list_id: listId,
    item_list_name: listName
  })
}

const viewProduct = ({ asset, currentRegion, event, selectedVariant }) => ({
  asset: assetProps(asset),
  event: event,
  region: {
    currency: currentRegion.storeCurrency,
    currencySymbol: currentRegion.currencySymbol,
    homeUrl: currentRegion.homeUrl,
    logoUrl: currentRegion.logoUrl
  },
  variant: {
    sku: selectedVariant.sku,
    studentPrice: selectedVariant.studentPrice
  }
})

const onViewProduct = (_action, { asset, currentRegion }) => {
  const { selectedVariant } = asset

  return ({
    ...viewProduct({
      asset,
      currentRegion,
      event: 'onViewProduct',
      selectedVariant: selectedVariant
    }),
    product_item: [
      itemProps({ asset, currentRegion, variant: selectedVariant })
    ]
  })
}

const selectedVariantProps = ({ asset, position, variant }) => {
  const { canonicalIsbn, imprintName, kind, title } = asset
  const { sku, studentPrice } = variant
  const name = formattedName({ canonicalIsbn, title })
  const id = formattedIsbn(canonicalIsbn)

  return ({
    brand: imprintName,
    category: kind,
    id: id,
    name: name,
    position: position,
    price: studentPrice,
    variant: sku
  })
}

const productClick = (
  { payload: { asset, position, variant, list } }, { currentRegion }
) => {
  const { listType } = list

  return ({
    ecommerce: {
      click: {
        actionField: { list: listType },
        products: [selectedVariantProps({ asset, position, variant })],
      }
    },
    event: "productClick",
    product_select_item: [
      recommendedItemProps({ asset, currentRegion, list, position, variant })
    ]
  })
}

const productClickSRP = (
  {
    payload: {
      canonicalIsbn, imprintName, kind, list, minPrice, position, title
    }
  },
  { currentRegion }
) => {
  const { listId, listName } = list
  const name = formattedName({ canonicalIsbn, title })
  const id = formattedIsbn(canonicalIsbn)

  return ({
    event: "productClickSRP",
    product_select_item: [
      {
        currency: currentRegion.storeCurrency,
        index: position,
        item_brand: imprintName,
        item_category: kind,
        item_id: id,
        item_list_id: listId,
        item_list_name: listName,
        item_name: name,
        price: minPrice
      }
    ]
  })
}

const promoCodeClick = ({ payload }) => {
  const { promoCodeInput, promoCodeStatus } = payload

  return ({
    event: "promoCodeClick",
    promoCode: {
      promoCodeInput,
      promoCodeStatus
    }
  })
}

export const eventsMap = {
  'gtm/Event': event,
  'gtm/consentUpdate': consentUpdate,
  'gtm/onViewProduct': onViewProduct,
  'gtm/productClick': productClick,
  'gtm/productClickSRP': productClickSRP,
  'gtm/promoCodeClick': promoCodeClick
}
