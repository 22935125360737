import "@reach/dialog/styles.css"
import 'regenerator-runtime/runtime'
import 'lazysizes'

export * from '@/global/accessible_focus'
export * from '@/global/assets_index'
export * from '@/global/simple_form_accessibility'
export * from '@/global/skip_to_content'
export * from '@/utils/honeybadger'

import ujs from "@rails/ujs"
import ReactOnRails from 'react-on-rails'

import Components from '@/Components'
import AssetPurchasedCard from '@/components/AssetPurchasedCard'
import CarbonNeutrality from '@/components/CarbonNeutrality'
import EducatorActionButton from '@/components/EducatorActionButton'
import PublisherAddToLibraryButton from
  '@/components/PublisherAddToLibraryButton'
import SearchResult from '@/components/SearchResult'
import StudentPricingPanel from '@/components/StudentPricingPanel'
import SubscriptionAssetPurchasedCard from
  '@/components/SubscriptionAssetPurchasedCard'
import VitalSourceEvents from '@/VitalSourceEvents'
const DEPLOYED_ENVIRONMENTS = require('../constants/deployed_environments')
const isDeployedEnvironment = DEPLOYED_ENVIRONMENTS.includes(
  process.env.RAILS_ENV
)

const SERVICE_WORKER = {
  location: '/service-worker.js',
  scope: '/'
}

ujs.start()

function startServiceWorker() {
  if ('serviceWorker' in navigator && isDeployedEnvironment) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(
        SERVICE_WORKER.location, { scope: SERVICE_WORKER.scope }
      )
    })
  }
}

startServiceWorker()

ReactOnRails.register({
  AssetPurchasedCard,
  CarbonNeutrality,
  Components,
  EducatorActionButton,
  PublisherAddToLibraryButton,
  SearchResult,
  StudentPricingPanel,
  SubscriptionAssetPurchasedCard,
  VitalSourceEvents
})
