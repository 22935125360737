import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import OnlineResource from '@/components/OnlineResourceModal/'
import { onlineResourceButtonTextSelector } from '@/selectors'

import ReduxProvider from '../ReduxProvider'
import Redirect from './RedirectModal'

let classNames = [
  'vst-btn--action',
  'vst-btn--orange',
  'vst-btn--read-now',
  'u-weight--normal'
]

const CoursewareModal = () => {
  const isBundle = useSelector(state => state.asset.isBundle)
  const text = useSelector(onlineResourceButtonTextSelector)
  const [isOnlineResourceShowing, setIsOnlineResourceShowing] = useState(false)
  const [isRedirectShowing, setIsRedirectShowing] = useState(false)
  const hasAlreadyOpenedCoursewareModal = useSelector(
    state => state.onlineResourceModal.redirectModal.shouldShow
  )
  const coursewareUrl = useSelector(
    state => state.onlineResourceModal.redirectModal.loginUrl
  )
  const assetTitle = useSelector(state => state.asset.title)

  if (isBundle) classNames.push(' u-push-half--top')

  const styles = classNames.join(' ')

  const handleRedirect = () => {
    setIsRedirectShowing(true)
    setTimeout(() => {
      window.location.assign(coursewareUrl)
    }, 2000)
  }

  const onOpen = () => {
    hasAlreadyOpenedCoursewareModal
      ? handleRedirect()
      : setIsOnlineResourceShowing(true)
  }
  const onHide = () => {
    setIsOnlineResourceShowing(false)
    setIsRedirectShowing(false)
  }

  return (
    <>
      <OnlineResource
        isShowing={isOnlineResourceShowing}
        onChangeShow={onHide}
        title={assetTitle}
      />
      <Redirect
        onClose={onHide}
        shouldShow={isRedirectShowing}
      />
      <button
        className={styles}
        data-category='Pricing Panel'
        onClick={onOpen}
        type='button'
      >
        {text}
      </button>
    </>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = () => {
  return (
    <ReduxProvider>
      <CoursewareModal />
    </ReduxProvider>
  )
}
export default Component
