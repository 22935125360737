import PropTypes from 'prop-types'
import React from 'react'

import ExternalLinkIcon from '@/components/ExternalLinkIcon'
import { translate } from '@/utils/translations'

const StudentStorePdpLink = ({ studentStoreUrl }) => {

  return (
    <a
      className='bulk-purchase-options'
      data-category='Pricing Panel'
      href={studentStoreUrl}
      rel='noopener noreferrer'
      target='_blank'
    >
      {translate('purchase.buy_in_student_store')}
      <ExternalLinkIcon theme='dark' />
    </a>
  )
}

export default StudentStorePdpLink

StudentStorePdpLink.propTypes = { studentStoreUrl: PropTypes.string.isRequired }
