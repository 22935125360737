import { combineReducers } from 'redux'

import accordion from './accordion'
import activeRegions from './activeRegions'
import activeSamplingRegions from './activeSamplingRegions'
import adminPermissions from './adminPermissions'
import adminRoleSwitcher from './adminRoleSwitcher'
import asset from './asset'
import bulkRedemptionGuidance from './bulkRedemptionGuidance'
import currentLocale from './currentLocale'
import currentRegion from './currentRegion'
import dashboardMenu from './dashboardMenu'
import featureFlags from './featureFlags'
import flashMessages from './flashMessages'
import ghostInspector from './ghostInspector'
import institutions from './institutions'
import instructorRecommendations from './instructorRecommendations'
import mobileMenu from './mobileMenu'
import onlineResourceModal from './onlineResourceModal'
import productRecommendationForm from './productRecommendationForm'
import publisherCampaigns from './publisherCampaigns'
import publisherCompanies from './publisherCompanies'
import publisherInstructorSearch from './publisherInstructorSearch'
import publisherUsers from './publisherUsers'
import recaptcha from './recaptcha'
import regionRecommendation from './regionRecommendation'
import samplingInstructorRequests from './samplingInstructorRequests'
import samplingInstructorRequestsExports
  from './samplingInstructorRequestsExports'
import samplingQuotas from './samplingQuotas'
import taxes from './taxes'
import user from './user'

export default combineReducers({
  accordion: accordion.reducer,
  activeRegions: activeRegions.reducer,
  activeSamplingRegions: activeSamplingRegions.reducer,
  adminPermissions: adminPermissions.reducer,
  adminRoleSwitcher: adminRoleSwitcher.reducer,
  asset: asset.reducer,
  bulkRedemptionGuidance: bulkRedemptionGuidance.reducer,
  currentLocale: currentLocale.reducer,
  currentRegion: currentRegion.reducer,
  dashboardMenu: dashboardMenu.reducer,
  featureFlags: featureFlags.reducer,
  flashMessages: flashMessages.reducer,
  ghostInspector: ghostInspector.reducer,
  institutions: institutions.reducer,
  instructorRecommendations: instructorRecommendations.reducer,
  mobileMenu: mobileMenu.reducer,
  onlineResourceModal: onlineResourceModal.reducer,
  productRecommendationForm: productRecommendationForm.reducer,
  publisherCampaigns: publisherCampaigns.reducer,
  publisherCompanies: publisherCompanies.reducer,
  publisherInstructorSearch: publisherInstructorSearch.reducer,
  publisherUsers: publisherUsers.reducer,
  recaptcha: recaptcha.reducer,
  regionRecommendation: regionRecommendation.reducer,
  samplingInstructorRequests: samplingInstructorRequests.reducer,
  samplingInstructorRequestsExports: samplingInstructorRequestsExports.reducer,
  samplingQuotas: samplingQuotas.reducer,
  taxes: taxes.reducer,
  user: user.reducer
})
