import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialStatuses = {
  error: null,
  fetching: false,
}

export const initialState = {
  accessCode: null,
  courseKey: null,
  isLoaded: false,
  redirectModal: {
    loginUrl: null,
    shouldShow: false
  },
  skipReveal: false,
  status: initialStatuses
}

const onlineResourceModal = createSlice({
  extraReducers,
  initialState,
  name: SLICES.onlineResourceModal,
  reducers
})

export const { actions } = onlineResourceModal
export default onlineResourceModal
