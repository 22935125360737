import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.onlineResourceModal]
})

const fetch = {
  fetchFailure: (state, { payload: error }) => ({
    ...state,
    status: { error, fetching: false }
  }),
  fetchStart: (state, _action) => ({
    ...state,
    status: { error: null, fetching: true }
  }),
  fetchSuccess: (
    state,
    { payload: { courseKey, accessCode, loginUrl, skipReveal } }
  ) => ({
    ...state,
    accessCode,
    courseKey,
    isLoaded: true,
    loginUrl,
    redirectModal: {
      ...state.redirectModal,
      loginUrl,
      shouldShow: true
    },
    skipReveal,
    status: { error: null, fetching: false }
  })
}

export const reducers = { ...fetch }

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
