import PropTypes from 'prop-types'
import React from 'react'

import { translate } from '@/utils/translations'

import { copyToClipboard } from './helpers'

const CopyButton = ({ id }) => (
  <button
    className='btn btn-light vst-btn--copy'
    data-testid='copy-button'
    name='copyButton'
    onClick={() => copyToClipboard({ id })}
    type='button'
  >
    {translate('common.copy')}
  </button>

)

export default CopyButton

CopyButton.propTypes = {
  id: PropTypes.string.isRequired
}
