import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => {
  if (payload[SLICES.publisherCompanies]) {
    return {
      companies: payload[SLICES.publisherCompanies],
      isLoading: false,
    }
  } else {
    return state
  }
}

const fetch = {
  fetchFailure: (state, _action) => ({
    ...state,
    isFetching: false
  }),
  fetchStart: (state, _action) => ({
    ...state,
    isFetching: true
  }),
  fetchSuccess: (state, { payload }) => ({
    companies: payload,
    isFetching: false
  })
}

const synchronize = {
  synchronizeFailure: (state, _action) => ({
    ...state,
    isFetching: false
  }),
  synchronizeStart: (state, _action) => ({
    ...state,
    isFetching: true
  }),
  synchronizeSuccess: (state, _action) => state
}

export const reducers = {
  ...fetch,
  ...synchronize
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
