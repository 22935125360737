import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VisuallyHidden from '@reach/visually-hidden'
import PropTypes from 'prop-types'
import React from 'react'

import { translate } from '@/utils/translations'
const CloseButton = ({ onDismiss }) => (
  <button className="close-button" onClick={onDismiss}>
    <VisuallyHidden>{translate('common.close')}</VisuallyHidden>
    <FontAwesomeIcon aria-hidden icon={faTimes} />
  </button>
)

export default CloseButton

CloseButton.propTypes = { onDismiss: PropTypes.func.isRequired }
