import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const fetch = {
  fetchFailure: (state, _action) => ({
    ...state,
    isFetching: false
  }),
  fetchStart: (state, _action) => ({
    ...state,
    isFetching: true
  }),
  fetchSuccess: (state, { payload }) => ({
    ...payload,
    isFetching: false
  })
}

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.adminPermissions]
})

export const reducers = {
  ...fetch
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
