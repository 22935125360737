import translations from './railsTranslations'

export const translate = (translationKey, parameters) => {
  const locale = window.locale
  let translatedText = translations[locale][translationKey]

  for (let key in parameters) {
    const regex = new RegExp('%{' + key + '}', 'g')

    translatedText = translatedText.replace(regex, parameters[key])
  }

  if(!translatedText) {
    console.warn(`missing translation for ${translationKey} in ${locale}`)
  }

  return translatedText
}
