require('./index.scss')

import PropTypes from 'prop-types'
import React from 'react'

import { translate } from '@/utils/translations'

import ContinueButton from './components/ContinueButton'
import ReturnPolicy from './components/ReturnPolicy'

const FirstStep = ({
  isSubscriptionAsset,
  setCurrentStep,
}) => (
  <div className='online-resource-modal-steps first'>
    <ReturnPolicy isSubscription={isSubscriptionAsset} />
    <ContinueButton
      onClick={() => setCurrentStep(2)}
      text={translate('common.continue')}
    />
  </div>
)

export default FirstStep

FirstStep.propTypes = {
  isSubscriptionAsset: PropTypes.bool.isRequired,
  setCurrentStep: PropTypes.func.isRequired
}
