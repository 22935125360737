import PropTypes from 'prop-types'
import React from 'react'
import { Provider } from 'react-redux'

import store from '@/store'

const ReduxProvider = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
)

export default ReduxProvider

ReduxProvider.propTypes = {
  children: PropTypes.element.isRequired
}
