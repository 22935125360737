import PropTypes from 'prop-types'
import React from 'react'

import { translate } from '@/utils/translations'

const DurationExpiration = ({ variant }) => (
  <Choose>
    <When condition={variant.duration === 'perpetual'}>
      {translate('purchase.buy_eTextbook')}
    </When>
    <Otherwise>
      {variant.duration}
    </Otherwise>
  </Choose>
)

export default DurationExpiration

DurationExpiration.propTypes = { variant : PropTypes.object }
