import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {
  isAllSelected: false,
  isExporting: false,
  isLoading: false,
  requests: {},
  searchTerm: null,
  selectedRegions: [],
}

const samplingInstructorRequests = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.samplingInstructorRequests,
  reducers: reducers
})

export const { actions } = samplingInstructorRequests
export default samplingInstructorRequests
