import PropTypes from 'prop-types'
import React from 'react'

const Icon = ({ name }) => (
  <img
    alt=''
    className='product-overview__feature-tile__image online-resource'
    src={require(`../../images/online-resource-${name}-disabled-icon.svg`)}
  />
)

export default Icon

Icon.propTypes = { name: PropTypes.string.isRequired }
