import loadable from '@loadable/component'
import VisuallyHidden from '@reach/visually-hidden'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const LoadableLibrary = loadable.lib(() => import('@/components/SrpBadges'))

import ReduxProvider from '../ReduxProvider'
import AssetDetails from './AssetDetails'
import Banner from './Banner'
import { SearchResultPropTypes } from './SearchResultProps'

const GTM_TYPE = 'gtm/productClickSRP'
const SRP_RECOMMENDED = 'Search Results'
const SRP_RECOMMENDED_ID = 'search_results'
const list = {
  listId: SRP_RECOMMENDED_ID,
  listName: SRP_RECOMMENDED
}

const SearchResult = ({
  assetProps,
  badges,
  bannerProps,
  coverImageUrl,
  formattedPrice,
  hasEdition,
  hasTaxInfoMessage,
  minPrice,
  packageTitle,
  position,
  productId,
  productUrl,
  taxInfoMessage
}) => {
  const { canonicalIsbn, imprintName, kind, title } = assetProps
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const dispatch = useDispatch()

  const onClickHandler = () => {
    dispatch({
      payload: {
        canonicalIsbn, imprintName, kind, list, minPrice, position, title
      },
      type: GTM_TYPE
    })
  }

  return(
    <>
      <a
        aria-hidden='true'
        href={`#${productId}`}
        tabIndex='-1'
      >
        <VisuallyHidden>
          {''}
        </VisuallyHidden>
      </a>
      <a
        className='product-search-result__link'
        href={productUrl}
        onClick={onClickHandler}
      >
        <Banner bannerProps={bannerProps} />
        <AssetDetails {...{
          assetProps,
          coverImageUrl,
          formattedPrice,
          hasEdition,
          hasTaxInfoMessage,
          packageTitle,
          taxInfoMessage
        }}
        />
      </a>
      <If condition={isLoaded}>
        <LoadableLibrary>
          {({ default: SrpBadges }) => <SrpBadges badges={badges} />}
        </LoadableLibrary>
      </If>
    </>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = ({
  assetProps,
  badges,
  bannerProps,
  coverImageUrl,
  formattedPrice,
  hasEdition,
  hasTaxInfoMessage,
  minPrice,
  packageTitle,
  position,
  productId,
  productUrl,
  taxInfoMessage
}) => (
  <ReduxProvider>
    <SearchResult {...{
      assetProps,
      badges,
      bannerProps,
      coverImageUrl,
      formattedPrice,
      hasEdition,
      hasTaxInfoMessage,
      minPrice,
      packageTitle,
      position,
      productId,
      productUrl,
      taxInfoMessage
    }}
    />
  </ReduxProvider>
)

export default Component

SearchResult.propTypes = SearchResultPropTypes
Component.propTypes = SearchResultPropTypes
