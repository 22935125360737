require('./index.scss')

import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Spinner from '@/components/Spinner'
import {
  fetch as fetchAccessCodes
} from '@/reducers/onlineResourceModal/asyncActions'
import { translate } from '@/utils/translations'

import ContinueButton from './components/ContinueButton'
import LastStepDetails from './components/LastStepDetails'

const LastStep = ({ vbid }) => {
  const assetVbid = vbid || useSelector(state => state.asset.vbid)
  const loginUrl = useSelector(state => state.onlineResourceModal.loginUrl)
  const isLoaded = useSelector(state => state.onlineResourceModal.isLoaded)
  const dispatch = useDispatch()

  useEffect(() => { dispatch(fetchAccessCodes({ vbid: assetVbid })) }, [])

  return (
    <Choose>
      <When condition={!isLoaded}>
        <Spinner />
      </When>
      <Otherwise>
        <div className='online-resource-modal-steps third'>
          <LastStepDetails />
          <If condition={loginUrl}>
            <ContinueButton
              text={translate('common.visit_website')}
              url={loginUrl}
            />
          </If>
        </div>
      </Otherwise>
    </Choose>
  )
}

export default LastStep

LastStep.propTypes = { vbid: PropTypes.string }
