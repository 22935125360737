import PropTypes from 'prop-types'
import React from 'react'

import ExternalLinkIcon from '@/components/ExternalLinkIcon'
import { translate } from '@/utils/translations'

const BulkStorePdpLink = ({ bulkStoreUrl }) => {

  return (
    <a
      className='bulk-purchase-options'
      data-category='Pricing Panel'
      href={bulkStoreUrl}
      rel='noopener noreferrer'
      target='_blank'
    >
      {translate('purchase.bulk_purchase')}
      <ExternalLinkIcon theme='dark' />
    </a>
  )
}

export default BulkStorePdpLink

BulkStorePdpLink.propTypes = { bulkStoreUrl: PropTypes.string.isRequired }
