import { createSlice } from '@reduxjs/toolkit'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  canShowGiftLink: false,
  features: {
    hasNotCoursewareFeatures: false
  },
  identifiers: [],
  licenses: [[{ expiration: '', vbid: '' }]],
  onlineResource: {
    error: null,
    isLoading: true,
    loginURL: null
  },
  packages: [],
  productDetails: {
    accessibilityFeatures: {
      onix: {
        accessibilityDetails: null,
        contentType: null
      },
      publisher: {
        certification: {
          certifiedBy: null,
          certifierCredential: null,
          certifierReport: null,
          conformsTo: null
        },
        metadata: {
          accessMode: null,
          accessModeSufficient: null,
          accessibilityAPI: null,
          accessibilityControl: null,
          accessibilityFeature: null,
          accessibilityHazard: null
        },
        summary: null
      },
      sourceFile: {
        certification: {
          certifiedBy: null,
          certifierCredential: null,
          certifierReport: null,
          conformsTo: null
        },
        metadata: {
          accessMode: null,
          accessModeSufficient: null,
          accessibilityAPI: null,
          accessibilityControl: null,
          accessibilityFeature: null,
          accessibilityHazard: null
        },
        summary: null
      }
    },
    bisacSubjects: [],
    chapters: [],
    description: '',
    otherIdentifiers: '',
  },
  quantity: 1,
  returnPolicy: {
    name: '',
    percentView: 0,
    returnItemTotal: 0,
    returnPastDays: 0,
    returnWithinDays: 0
  },
  selectedVariant: { duration: '', id: '' },
  subtitle: '',
  tableOfContents: {
    error: null,
    isLoading: true,
    response: []
  },
  title: '',
  variants: [],
}

const asset = createSlice({
  extraReducers,
  initialState,
  name: 'asset',
  reducers
})

export const { actions } = asset
export default asset
