import PropTypes from 'prop-types'
import React from "react"

const AssetDetails = ({
  assetProps,
  coverImageUrl,
  hasEdition,
  hasTaxInfoMessage,
  packageTitle,
  formattedPrice,
  taxInfoMessage
}) => {
  const {
    assetFormat,
    author,
    edition,
    hasAuthor,
    hasCoachMe,
    isPackage,
    isShowingFormatOnDetails,
    subtitle,
    title,
    truncatedTitle
  } = assetProps

  return(
    <div>
      <div className='type-bg--cloud2'>
        <div className={[
          'product-search-result__image',
          'product-search-result__image--tile-view'
        ].join(' ')}
        >
          <div className='product-cover'>
            <img
              alt=''
              className='product-cover__image'
              src={coverImageUrl}
            />
          </div>
        </div>
      </div>
      <div className='product-search-result__details'>
        <h2
          className={[
            'product-search-result__title heading-body',
            'heading--tiny',
            'u-flush--bottom'
          ].join(' ')}
        >
          <span title={title}>
            <If condition={isPackage}>
              <span className='type--pink'>{packageTitle}</span>
            </If>
            {truncatedTitle}
          </span>
        </h2>
        <div className='subtitle subtitle-srp'>
          {subtitle}
        </div>
        <If condition={hasAuthor}>
          <div className='product-search-result__author'>
            {author}
          </div>
        </If>

        <ul className='horizontal-dictionary'>
          <If condition={hasEdition}>
            <li className='product-search-result__edition'>
              {edition}
            </li>
          </If>

          <If condition={isShowingFormatOnDetails}>
            <li className='product-search-result__edition'>
              {assetFormat}
            </li>
          </If>

          <li className='product-search-result__price'>
            <div>
              <div>
                {formattedPrice}
              </div>
              <If condition={hasTaxInfoMessage}>
                <div className='type--orange'>
                  {taxInfoMessage}
                </div>
              </If>
            </div>
          </li>

          <If condition={hasCoachMe}>
            <li>
              <img
                alt='CoachMe Included'
                className='coach_me'
                src={require('@/images/coachme-included.svg')}
              />
            </li>
          </If>
        </ul>
      </div>
    </div>
  )
}

export default AssetDetails

AssetDetails.propTypes = {
  assetProps: PropTypes.shape({
    assetFormat: PropTypes.string.isRequired,
    author: PropTypes.string,
    edition: PropTypes.string,
    hasAuthor: PropTypes.bool.isRequired,
    hasCoachMe: PropTypes.bool.isRequired,
    isPackage: PropTypes.bool.isRequired,
    isShowingFormatOnDetails: PropTypes.bool.isRequired,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    truncatedTitle: PropTypes.string.isRequired
  }),
  coverImageUrl: PropTypes.string.isRequired,
  formattedPrice: PropTypes.string.isRequired,
  hasEdition: PropTypes.bool.isRequired,
  hasTaxInfoMessage: PropTypes.bool.isRequired,
  packageTitle: PropTypes.string,
  taxInfoMessage: PropTypes.string
}
