require('./index.scss')

import React, { useEffect, useState } from 'react'

import ReduxProvider from '@/components/ReduxProvider'
import { translate } from '@/utils/translations'

import SkeletonThemeWrapper from '../SkeletonThemeWrapper'
import Button from './Button'

const CarbonNeutralityDialog = () => {
  const [isShowingComponent, setIsShowingComponent] = useState(false)

  useEffect(() => {
    setIsShowingComponent(true)
  })

  return (
    <Choose>
      <When condition={isShowingComponent}>
        <div id='carbon-neutrality'>
          <Button />
          <p className='u-align--center' >
            {translate('pages.store_home.carbon_neutrality.purchase')}
          </p>
        </div>
      </When>
      <Otherwise>
        <SkeletonThemeWrapper count={1} />
      </Otherwise>
    </Choose>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = () => (
  <ReduxProvider>
    <CarbonNeutralityDialog />
  </ReduxProvider>
)
export default Component
