import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import ButtonWithSpinner from './ButtonWithSpinner'

const BUTTON_CLASSES = 'vs-btn--action vs-btn--orange'

const ButtonTo = ({
  action,
  children,
  className,
  dataAction,
  dataCategory,
  location,
  method,
  onSubmit,
  params = {},
  target
}) => {

  useEffect(() => {
    import('@/utils/csrfToken').then(tokenizer => {
      setToken(tokenizer.default())
    }).catch(error => {
      // Need to handle this better
      console.error(error)
    })
  }, [])

  const [ token, setToken ] = useState('')

  const [ isWaiting, setIsWaiting ] = useState(false)

  const onHandleSubmit = event => {
    onSubmit(event)
    setIsWaiting(true)
  }

  const submitProp = onSubmit ? { onSubmit: onHandleSubmit } : {}

  if (!['get', 'post'].includes(method)) {
    params['_method'] = method
    method = 'post'
  }

  return (
    <Choose>
      <When condition={isWaiting}>
        <ButtonWithSpinner {...{ className }} />
      </When>
      <Otherwise>
        <form
          action={action}
          data-location={location}
          method={method}
          target={target}
          {...submitProp}
        >
          <input
            data-testid='token'
            name='authenticity_token'
            type='hidden'
            value={token}
          />
          {Object.keys(params).map(paramKey => (
            <input
              key={paramKey}
              name={paramKey}
              type='hidden'
              value={params[paramKey]}
            />
            ))}
          <button
            className={className}
            data-action={dataAction}
            data-category={dataCategory}
            formMethod={method}
            type='submit'
          >
            {children}
          </button>
        </form>
      </Otherwise>
    </Choose>
  )
}

export default ButtonTo

ButtonTo.defaultProps = { className: BUTTON_CLASSES, target: '_self' }

ButtonTo.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string.isRequired,
  dataAction: PropTypes.string,
  dataCategory: PropTypes.string,
  location: PropTypes.string,
  method: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  params: PropTypes.object,
  target: PropTypes.string.isRequired
}
