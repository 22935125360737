require('./index.scss')

import PropTypes from 'prop-types'
import React from 'react'

import CopyButton from './CopyButton'
import { highlight } from './helpers'
import Label from './Label'

const Clipboard = ({
  ariaLabel,
  id,
  label,
  textToBeCopied,
  toolTipText
}) => {
  const requiredAriaLabel = label ? undefined : ariaLabel

  return (
    <>
      <Label {... { id, label, toolTipText }} />
      <div className='input-group clipboard-input-group'>
        <input
          aria-label={requiredAriaLabel}
          className='form-control default-cursor clipboard__input'
          id={id}
          onClick={highlight}
          readOnly
          type='text'
          value={textToBeCopied}
        />
        <CopyButton {...{ id }} />
      </div>
    </>
  )
}

export default Clipboard

Clipboard.propTypes = {
  ariaLabel: function (props, propName, componentName) {
    if (props['label'] === undefined && (props['ariaLabel'] === undefined
      || !(typeof props['ariaLabel'] === 'string'))) {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' + ' `' + componentName
        + '`. Aria label must be a string and is required if there is no label.'
      )
    }
  },
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  textToBeCopied: PropTypes.string.isRequired,
  toolTipText: PropTypes.string
}
