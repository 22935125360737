import PropTypes from 'prop-types'
import React from 'react'

const data = {
  action: 'UnverifiedRequest',
  category: 'Sampling:UnverifiedRequest'
}

const classNames = [
  'vst-btn--action',
  'vst-btn--orange',
  'vst-btn--read-now',
  'u-weight--normal'
].join(' ')

const RequestSampleButton = ({
  routes,
  translations
}) => (
  <a
    className={classNames}
    data-action={data.action}
    data-category={data.category}
    href={routes.new_instructor_asset_sampling_request}
  >
    {translations.purchase_request_sample}
  </a>
)

export default RequestSampleButton

RequestSampleButton.propTypes = {
  routes: PropTypes.shape({
    new_instructor_asset_sampling_request: PropTypes.string.isRequired
  }),
  translations: PropTypes.shape({
    purchase_request_sample: PropTypes.string.isRequired
  })
}
