import React from 'react'

import Spinner from './Spinner'

export const MobileSpinner = () => (
  <div
    className='panel panel-default'
    data-test='datatable-status-row'
    key='pending_spinner'
  >
    <div className='entry'>
      <div className='panel-body text-center'>
        <Spinner isInsideTable={false} />
      </div>
    </div>
  </div>
)
