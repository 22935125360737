import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialStatuses = {
  error: null,
  fetching: false
}

export const initialState = {
  isValidEmail: false,
  query: '',
  searchSuggestions: [],
  status: initialStatuses
}

const publisherInstructorSearch = createSlice({
  extraReducers,
  initialState,
  name: SLICES.publisherInstructorSearch,
  reducers
})

export const { actions } = publisherInstructorSearch
export default publisherInstructorSearch
