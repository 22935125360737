import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  b2bLink: '',
  bookshelf: {
    link: '',
    text: ''
  },
  firstName: '',
  hideFacultySamplingLink: '',
  isLoggedIn: false,
  lastName: '',
  navigation: {
    backofficePermissions: [],
    hasDashboard: false,
    hasInstructorExperience: false,
    hasNonVstAdminBackofficeAccess: false,
    hasRoleBasedDashboard: false,
    roleBasedDashboardPath: ''
  }
}

const mobileMenu = createSlice({
  extraReducers,
  initialState,
  name: SLICES.mobileMenu,
  reducers
})

export const { actions } = mobileMenu
export default mobileMenu
