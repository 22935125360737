import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'

import componentsMap from '@/constants/componentsMap'
import store from '@/store'
import { eventDispatcher } from '@/utils/eventsHelper'

import ThirdPartyErrorBoundary from './ThirdPartyErrorBoundary'

const Components = ({ events, name, props, state }) => {
  if (state || events) {
    useEffect(() => { eventDispatcher({ events, state, store }) }, [])
  }

  const path = componentsMap[name] || name

  const Loadable = loadable(() => import(
    `@/components/${path}/index.jsx`
  ))

  return (
    <ThirdPartyErrorBoundary>
      <Provider store={store}>
        <Loadable {...props} />
      </Provider>
    </ThirdPartyErrorBoundary>
  )
}

Components.propTypes = {
  events: PropTypes.array,
  name: PropTypes.string.isRequired,
  props: PropTypes.object,
  state: PropTypes.object,
}

export default Components
