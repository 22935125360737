import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Dialog from '@/components/Dialog'
import Spinner from '@/components/Spinner'
import { translate } from '@/utils/translations'

const RedirectModal = ({ shouldShow, onClose }) => {
  const assetTitle = useSelector(state => state.asset.title)
  const [isShowing, setIsShowing] = useState(shouldShow)

  return (
    <Dialog
      heading={translate('common.you_are_leaving_vitalsource')}
      id='redirect-modal'
      isShowing={isShowing}
      onChangeShow={setIsShowing}
      onClose={onClose}
    >
      <Spinner />
      <p>
        {`${translate('common.courseware_redirect_text')} ${assetTitle}.`}
      </p>
    </Dialog>
  )
}

export default RedirectModal

RedirectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  shouldShow: PropTypes.bool.isRequired
}
