import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialStatuses = {
  error: null,
  fetching: false,
}

export const initialState = {
  stateOptions: null,
  status: initialStatuses
}

const productRecommendationForm = createSlice({
  extraReducers,
  initialState,
  name: SLICES.productRecommendationForm,
  reducers
})

export const { actions } = productRecommendationForm
export default productRecommendationForm
