import PropTypes from 'prop-types'
import React from 'react'

import ButtonTo from '@/components/ButtonTo'

const buttonClasses = [
  'vst-btn--large-full-width',
  'vst - btn--orange',
  'u-weight--normal',
  'qa-pub-add-to-library'
].join(' ')

const PublisherAddToLibraryButton = ({ location, url, text }) => (
  <ButtonTo
    action={url}
    className={buttonClasses}
    location={location}
    method='post'
    target='_blank'
  >
    {text}
  </ButtonTo>
)

export default PublisherAddToLibraryButton

PublisherAddToLibraryButton.propTypes = {
  location: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}
