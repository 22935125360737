import React, { useState } from 'react'

import { translate } from '@/utils/translations'

import Modal from './Modal'
const CarbonNeutralityButton = () => {
  const key = 'pages.store_home.carbon_neutrality'
  const [isShowing, setIsShowing] = useState(false)
  return (
    <button
      className='vst-btn--transparent styled-as-link'
      onClick={() => setIsShowing(true)}
      type='button'
    >
      <div id='carbon-neutrality-modal-trigger'>
        <div className='flower' />
        {translate(`${key}.planet_smart`)}
      </div>
      <Modal
        isShowing={isShowing}
        onChangeShow={setIsShowing}
      />
    </button>
  )
}

export default CarbonNeutralityButton
