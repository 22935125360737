import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {
  isFetching: true,
  page: 1,
  rows: [],
  totalPages: 1,
}

const samplingQuotas = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.samplingQuotas,
  reducers: reducers
})

export const { actions } = samplingQuotas
export default samplingQuotas
