import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const update = {
  updateFailure: (state, { payload: { accept, error, userId } }) => ({
    ...state,
    requests: {
      ...state.requests,
      [userId]: {
        ...state.requests[userId],
        errorMessage: error.data.errorMessage,
        isSelected: false,
        isUpdating: false,
        status: `${accept ? 'accept' : 'deny'}Failed`,
      }
    }
  }),
  updateStart: (state, { payload: { userId } }) => ({
    ...state,
    requests: {
      ...state.requests,
      [userId]: {
        ...state.requests[userId],
        errorMessage: null,
        isUpdating: true
      }
    }
  }),
  updateSuccess: (state, { payload: { status, userId } }) => ({
    ...state,
    isAllSelected: false,
    requests: {
      ...state.requests,
      [userId]: {
        ...state.requests[userId],
        isActionable: false,
        isSelected: false,
        isUpdating: false,
        status
      }
    }
  })
}

const toggleRequestIsSelected = (state, { payload: userId }) => ({
  ...state,
  requests: {
    ...state.requests,
    [userId]: {
      ...state.requests[userId],
      isSelected: !state.requests[userId].isSelected
    }
  }
})

const setSelectedStatusForAll = ({ isSelected, requests }) => (
  requests.reduce((newRequests, request) => {
    if (request.isActionable) {
      newRequests[request.userId] = { ...request, isSelected }
    } else {
      newRequests[request.userId] = request
    }

    return newRequests
  }, {})
)

const selectAll = (state, _action) => ({
  ...state,
  isAllSelected: true,
  requests: setSelectedStatusForAll({
    isSelected: true,
    requests: Object.values(state.requests)
  })
})

const deselectAll = (state, _action) => ({
  ...state,
  isAllSelected: false,
  requests: setSelectedStatusForAll({
    isSelected: false,
    requests: Object.values(state.requests)
  })
})

const updateSearchTerm = (state, { payload: searchTerm }) => ({
  ...state, searchTerm
})

const toggleSelectedRegionFilter = (state, { payload: regionKey }) => {
  const { selectedRegions } = state
  let newSelectedRegions

  if (selectedRegions.includes(regionKey)) {
    newSelectedRegions = selectedRegions.filter(key => key !== regionKey)
  } else {
    newSelectedRegions = selectedRegions.concat(regionKey)
  }

  return {
    ...state,
    selectedRegions: newSelectedRegions
  }
}

const initialLoad = (state, { payload }) => ({
  ...state,
  ...payload[SLICES.samplingInstructorRequests]
})

export const reducers = {
  ...update,
  deselectAll,
  selectAll,
  toggleRequestIsSelected,
  toggleSelectedRegionFilter,
  updateSearchTerm
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
