import PropTypes from 'prop-types'
import React from 'react'

const ContinueButton = ({ text, onClick, url }) => {

  return (
    <div className='continue-button-wrap'>
      <a
        className='btn continue-button'
        data-category='OnlineResourceModal'
        href={url || '#'}
        onClick={onClick}
        rel='noopener noreferrer'
        target={url ? '_blank' : undefined}
      >
        {text}
      </a>
    </div>
  )
}

export default ContinueButton

ContinueButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  url: PropTypes.string
}
