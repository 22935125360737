import React from 'react'
import { useSelector } from 'react-redux'

import Clipboard from '@/components/Clipboard'
import settings from '@/utils/settings'
import { translate } from '@/utils/translations'

import Icon from './Icon'

const LastStepDetails = () => {
  const accessCode = useSelector(state => state.onlineResourceModal.accessCode)
  const courseKey = useSelector(state => state.onlineResourceModal.courseKey)
  const isSkipReveal = useSelector(
    state => state.onlineResourceModal.skipReveal
  )

  return (
    <Choose>
      <When condition={isSkipReveal}>
        <p>{translate('assets.show.online_resource.modal.instructions')}</p>
      </When>
      <Otherwise>
        <div className='image-and-clipboard'>
          <Icon name='visit' />
          <div className='clipboards'>
            <If condition={courseKey}>
              <Clipboard
                id={'course-code'}
                label={translate('common.course_key')}
                textToBeCopied={courseKey}
                toolTipText={translate('common.course_key_tooltip')}
              />
            </If>
            <Clipboard
              id={'access-code'}
              label={translate('common.access_code')}
              textToBeCopied={accessCode}
              toolTipText={translate('common.access_code_tooltip')}
            />
          </div>
        </div>
        <p>
          {translate('common.this_info_can_still_be_found_in')}
          {' '}
          <a
            data-category='OnlineResourceModal'
            href={settings.account_center_base_url}
            rel='noopener noreferrer'
            target='_blank'
          >
            {translate('common.account_center')}
          </a>
        </p>
      </Otherwise>
    </Choose>
  )
}

export default LastStepDetails
