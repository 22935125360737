import PropTypes from 'prop-types'
import React from 'react'

const Banner = ({ bannerProps: { hasBanner, text } }) => (
  <If condition={hasBanner}>
    <div className='asset-banner-wrapper'>
      <div className='banner'>
        {text}
      </div>
    </div>
  </If>
)

export default Banner

Banner.propTypes = {
  bannerProps: PropTypes.shape({
    hasBanner: PropTypes.bool,
    text: PropTypes.string
  })
}
