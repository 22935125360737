import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import currencyShape from '@/propTypeShapes/currency'
import taxesShape from '@/propTypeShapes/taxes'
import { quantitySelector } from '@/selectors'
import LocalePrice from '@/utils/factory/price/localePrice'

import ReduxProvider from '../ReduxProvider'
import TaxInfoMessage from './TaxInfoMessage'

const Total = ({
  currency,
  isRepeatBillingSubscription,
  isZeroDecimalCurrency,
  taxes: { flatTaxMultiplier, taxMultiplier },
  total: { amount, isFree, translations }
}) => {
  const totalFromState = useSelector(
    state => state.asset.selectedVariant.studentPrice
  )

  const quantity = useSelector(quantitySelector)

  const [serverTotal, setServerTotal] = useState(amount)

  useEffect(() => {
    const total = quantity * totalFromState

    setServerTotal(total)
  }, [totalFromState, quantity])

  const { common_no_price_product, purchase_per_month } = translations

  const localePrice = new LocalePrice({
    currency,
    flatTaxMultiplier,
    isZeroDecimalCurrency,
    options: { hideCurrencyLabel: false },
    price: serverTotal,
    taxMultiplier,
    translations: { common_no_price_product }
  })

  return (
    <>
      <TaxInfoMessage />
      <div className='total-price'>
        {localePrice.formattedAmount}
        <If condition={currency.showing && !isFree}>
          {currency.storeCurrency}
        </If>
        <If condition={isRepeatBillingSubscription}>
          {' '}
          {purchase_per_month}
        </If>
      </div>
    </>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = ({
  currency, isRepeatBillingSubscription, isZeroDecimalCurrency, taxes, total
}) => (
  <ReduxProvider>
    <Total {...{
      currency,
      isRepeatBillingSubscription,
      isZeroDecimalCurrency,
      taxes,
      total
    }}
    />
  </ReduxProvider>
)

export default Component

Total.propTypes = {
  currency: currencyShape,
  isRepeatBillingSubscription: PropTypes.bool.isRequired,
  isZeroDecimalCurrency: PropTypes.bool.isRequired,
  taxes: taxesShape,
  total: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    isFree: PropTypes.bool.isRequired,
    translations: PropTypes.shape({
      common_no_price_product: PropTypes.string.isRequired,
      purchase_per_month: PropTypes.string.isRequired,
    })
  })
}

Component.propTypes = {
  currency: currencyShape,
  isRepeatBillingSubscription: PropTypes.bool.isRequired,
  isZeroDecimalCurrency: PropTypes.bool.isRequired,
  taxes: taxesShape,
  total: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    isFree: PropTypes.bool.isRequired,
    translations: PropTypes.shape({
      common_no_price_product: PropTypes.string.isRequired,
      purchase_per_month: PropTypes.string.isRequired,
    })
  })
}
