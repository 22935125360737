require('./index.scss')

import darkIcon from 'images/external-link-icon-blue.svg'
import lightIcon from 'images/external-link-icon-light-blue.svg'
import PropTypes from 'prop-types'
import React from 'react'

import { translate } from '@/utils/translations'

const DARK = 'dark'
const LIGHT = 'light'

const ExternalLinkIcon = ({ theme }) => {
  const iconColor = theme === DARK ? darkIcon : lightIcon

  return (
    <img
      alt={translate('common.opens_in_new_window')}
      className='external-icon'
      src={iconColor}
    />
)}

export default ExternalLinkIcon

ExternalLinkIcon.propTypes = {
  theme: PropTypes.oneOf([DARK, LIGHT]).isRequired
}
