import PropTypes from 'prop-types'
import React from 'react'

import ButtonTo from '@/components/ButtonTo'

const classNames = [
  'vst-btn--action',
  'vst-btn--orange',
  'vst-btn--read-now',
  'u-weight--normal',
  'instructor_add_to_library_action'
].join(' ')

const data = {
  action: 'AddToLibrary:SRP-PDP',
  category: 'Sampling:AddToLibrary'
}

const InstructorRenewButton = ({
  routes,
  translations
}) => (
  <ButtonTo
    action={routes.renew_instructor_asset_sampling_records}
    className={classNames}
    data-action={data.action}
    data-category={data.category}
    method='post'
  >
    {translations.common_renew}
  </ButtonTo>
)

export default InstructorRenewButton

InstructorRenewButton.propTypes = {
  routes: PropTypes.shape({
    renew_instructor_asset_sampling_records: PropTypes.string.isRequired
  }),
  translations: PropTypes.shape({
    common_renew: PropTypes.string.isRequired
  })
}
