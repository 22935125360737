import http from '@/utils/http'
import { routeFor } from '@/utils/router'

import onlineResourceModal from './index'

const { actions } = onlineResourceModal

export const fetch = ({ vbid }) => dispatch => {
  dispatch(actions.fetchStart())

  return http.get(routeFor('apiAccessCodes'), { vbid }).then(
    response => {
      dispatch(actions.fetchSuccess(response))
    }
  ).catch(
    error => dispatch(actions.fetchFailure(error))
  )
}
