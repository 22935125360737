import PropTypes from 'prop-types'
import React from 'react'

import tooltipIcon from '@/images/tooltip_icon.svg'

const Icon = ({
  styles,
  icon
}) => (
  <img
    alt=''
    aria-hidden
    className={styles || null}
    src={icon || tooltipIcon}
  />
)

export default Icon

Icon.propTypes = {
  icon: PropTypes.string,
  styles: PropTypes.string
}
