import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {
  campaigns: {},
  isUpdating: false
}

const publisherCampaigns = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.publisherCampaigns,
  reducers: reducers
})

export const { actions } = publisherCampaigns
export default publisherCampaigns
