import PropTypes from 'prop-types'
import React from 'react'

import Spinner from '@/components/Spinner'

const ButtonWithSpinner = ({ className }) => (
  <button
    className={className}
    disabled
    type='button'
  >
    <Spinner {...{ styles: 'sk-spinner sk-spinner-three-bounce-white' }} />
  </button>
)

export default ButtonWithSpinner

ButtonWithSpinner.propTypes = { className: PropTypes.string.isRequired }
