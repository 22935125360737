import PropTypes from 'prop-types'
import React from 'react'

import { localePrice } from '@/utils/priceLocalization'

import DurationExpiration from './DurationExpiration'

const Variant = ({ variant }) => {
  const { studentPrice } = variant

  return (
    <div className='u-push-half--top bulk-pricing-variant'>
      <DurationExpiration {...{ variant }} />
      {': '}
      {localePrice(studentPrice, { hideCurrencyLabel: true })}
    </div>
  )
}

export default Variant

Variant.propTypes = { variant: PropTypes.object }
