import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { usePopper } from 'react-popper'

const PopperContent = ({
  referenceElement,
  children,
  isOpen,
  onEnter,
  onLeave,
}) => {
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [10, 10]
        }
      }
    ],
    placement: 'bottom'
  })

  return(
    <If condition={isOpen}>
      <div
        className='new_tooltip__popper dark'
        data-testid='popper-content'
        id='popper-content'
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {children}
        <div id='arrow' ref={setArrowElement} style={styles.arrow} />
      </div>
    </If>
  )
}

export default PopperContent

PopperContent.propTypes = {
  children: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  referenceElement: PropTypes.object
}
