import PropTypes from 'prop-types'
import React from 'react'

import CoursewareModal from './CoursewareModal'

const CoursewareButton = ({
  isShowingCustomerSupportUrl,
  translations,
  supportUrl,
}) => {

  const {
    common_access_code_send_by_email,
    common_customer_support
  } = translations

  return(
    <Choose>
      <When condition={isShowingCustomerSupportUrl}>
        <p className='copy--smaller copy-line--medium u-push-half--top'>
          {common_access_code_send_by_email}
          {' '}
          <a
            className='text-lowercase'
            data-category='Pricing Panel'
            href={supportUrl}
            rel='noopener noreferrer'
            target='_blank'
          >
            {common_customer_support}
          </a>
        </p>
      </When>
      <Otherwise>
        <CoursewareModal />
      </Otherwise>
    </Choose>
  )
}

export default CoursewareButton

CoursewareButton.propTypes = {
  isShowingCustomerSupportUrl: PropTypes.bool.isRequired,
  supportUrl: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    common_access_code_send_by_email: PropTypes.string.isRequired,
    common_customer_support: PropTypes.string.isRequired,
  })
}
